<!--
--------------------------------------------------------------------------------
<copyright file="BendingAvailabilityHist.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.scale_selected_period') }}</p>
      <p class="text">{{ $t('widget_info_text.evaluated_machine_status') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.tip_hist_txt') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.time_scale_h') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.lines') }}</summary>
      <p class="text">{{ $t('widget_info_text.lines_txt_maq_stat') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_cat') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.lines_tip') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-range-filter />
      <shift-filter />
      <group-x-axis-by />
      <details>
        <summary>
          <span class="list">{{ $t('widget_info_text.more_filter') }}</span>
        </summary>
        <img src="images/info/threeDotsMenu.jpg" class="list" />
        <download-data />
        <aggregates />
        <change-display />
      </details>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';
import TimeRangeFilterInfo from '@/components/Charts/widgetInfo/TimeRangeFilter.vue';
import DownloadDataInfo from './DownloadData.vue';
import GroupXAxisByInfo from './GroupXAxisBy.vue';
import AggregatesInfo from './Aggregates.vue';
import ChangeDisplayInfo from './ChangeDisplay.vue';

@Component({
  components: {
    'shift-filter': ShiftFilterInfo,
    'time-range-filter': TimeRangeFilterInfo,
    'download-data': DownloadDataInfo,
    'group-x-axis-by': GroupXAxisByInfo,
    aggregates: AggregatesInfo,
    'change-display': ChangeDisplayInfo,
  },
})
export default class BendingAvailabilityHistInfo extends Vue {}
</script>
